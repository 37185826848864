(function($) {
	"use strict";
	$(function() {
		// Check the elements exist then hide
		if ( $('.acf-field-592d8f43b5e89').length ) {
			$('.acf-field-592d8f43b5e89').hide();
		}

		if ( $('.acf-field-592d8f7ab5e8a').length ) {
			$('.acf-field-592d8f7ab5e8a').hide();
		}
	});
})(jQuery);
