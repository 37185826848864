(function () {
  document.addEventListener('click', function (event) {
    var $target = event.target;

    var $itemToReveal = document.querySelector('.pushin');
    var $itemToReveal2 = document.querySelector('.overlay');
  //overkill on click specificity for IE10
    if ($target.classList.contains('open-pushin') || $target.parentNode.classList.contains('open-pushin') || $target.parentNode.parentNode.classList.contains('open-pushin') || $target.classList.contains('after')) {
      $itemToReveal.classList.add('showMe');
      $itemToReveal2.classList.add('showMe');
      event.preventDefault();
      return;
    }
    if ($target.classList.contains('close-pushin') || $target.parentNode.classList.contains('close-pushin')) {
      $itemToReveal.classList.remove('showMe');
      $itemToReveal2.classList.remove('showMe');
      event.preventDefault();
      return;
    }
    if ($target.classList.contains('open-search') || $target.parentNode.classList.contains('open-search')) {
      if (document.body.classList.contains('show-search')) {
        document.body.classList.remove('show-search');
      } else {
        document.body.classList.add('show-search');
      }
      event.preventDefault();
      return;
    }

    if ($target.classList.contains('overlay')) {
      $itemToReveal.classList.remove('showMe');
      $itemToReveal2.classList.remove('showMe');
      event.preventDefault();
      return;
    }
  });
})();

objectFitImages();