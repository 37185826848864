(function($) {
	'use strict';
	$(function() {
		$('#equipment-brand-association').change(function() {
			var brand = $(this).val();

			$.ajax({
				type : 'POST',
				dataType : 'json',
				url : equipmentBrandAssociation.ajaxurl,
				data : {
					action: 'admin-update-types-select',
					security : equipmentBrandAssociation.security,
					brand : brand,
				},
				success: function(data) {
					if (data.error == false) {
						// Hide some things!
						$('#equipment-type-association').show();
						$('.no-types').addClass('hide');
						$('.no-types-message').addClass('hide');

						// Remove all all options, but not the first
						$('#equipment-type-association option:gt(0)').remove();

						// Create the new options and append to the type select element
						$.each(data.options, function(key,value) {
							$('#equipment-type-association')
								.append($('<option></option>')
								.attr('value', key).text(value));
						});
					} else {
						// Get rid of the select
						$('#equipment-type-association').hide();
						// Hide the message that's already there
						$('.no-types').addClass('hide');
						// Show the user a message
						$('.no-types-message').removeClass('hide');
					}
				}
			});
		});
	});
})(jQuery);
