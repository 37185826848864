(function($) {
    "use strict";
    $(function() {
        $('#searchorderselect').change(function() {
            var sort_order = $(this).find(':selected').data('href');
            var search_term = $('#s').val();

            if ($(this).val() == 'Relevance') {
                var href = '/?s=' + search_term + '';
            } else {
                var href = '/?s=' + search_term + '&order_by=' + sort_order;
            }

            // Redirect the user to the newly formed href
            window.location.href = href;
        });
    });
})(jQuery);
