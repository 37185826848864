// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

var lory;
var initWidth;
'use strict';

function checkWidth () {
  // vanilla JS window width and height
  var w = window;
  var d = document;
  var e = d.documentElement;
  var g = d.getElementsByTagName('body')[0];
  initWidth = w.innerWidth || e.clientWidth || g.clientWidth;
  // y=w.innerHeight||e.clientHeight||g.clientHeight;
  // console.log('width = ' + initWidth);
}

document.addEventListener('DOMContentLoaded', function () {

  Array.prototype.slice.call(document.querySelectorAll('.image-slider')).forEach(function (element, index) {

    element.addEventListener('before.lory.init', handleDotEvent);
    element.addEventListener('after.lory.init', handleDotEvent);
    element.addEventListener('after.lory.slide', handleDotEvent);
    element.addEventListener('on.lory.resize', handleDotEvent);

    element.addEventListener('before.lory.init', showHideControls);
    element.addEventListener('after.lory.init', showHideControls);
    element.addEventListener('after.lory.slide', showHideControls);
    element.addEventListener('on.lory.resize', showHideControls);

    element.addEventListener('before.lory.init', checkWidth);
    element.addEventListener('after.lory.init', checkWidth);
    element.addEventListener('after.lory.slide', checkWidth);
    element.addEventListener('on.lory.resize', checkWidth);
    // var dotCount3 = element.querySelectorAll('.js_slide').length;
    //   var dotContainer = simpleDots3.querySelector('.js_dots');
    var sliderCount = element.querySelectorAll('.js_slide').length;
    var dotContainer = element.querySelector('.js_dots');
    var dotNavigationSlider;
    var dotListItem = document.createElement('li');
    dotListItem.classList.add('dot');

    function handleDotEvent (e) {
      if (e.type === 'before.lory.init') {
        for (var i = 0, len = sliderCount; i < len; i++) {
          var clone = dotListItem.cloneNode();
          dotContainer.appendChild(clone);
        }
        dotContainer.childNodes[0].classList.add('active');
      }
      if (e.type === 'after.lory.init') {
        for (var i1 = 0, len1 = sliderCount; i1 < len1; i1++) {
          dotContainer.childNodes[i1].addEventListener('click', function (e) {
            dotNavigationSlider.slideTo(Array.prototype.indexOf.call(dotContainer.childNodes, e.target));
          });
        }
      }
      if (e.type === 'after.lory.slide') {
        for (var i2 = 0, len2 = dotContainer.childNodes.length; i2 < len2; i2++) {
          dotContainer.childNodes[i2].classList.remove('active');
        }
        dotContainer.childNodes[e.detail.currentSlide - 1].classList.add('active');
      }
      if (e.type === 'on.lory.resize') {
        for (var i3 = 0, len3 = dotContainer.childNodes.length; i3 < len3; i3++) {
          dotContainer.childNodes[i3].classList.remove('active');
        }
        dotContainer.childNodes[0].classList.add('active');
      }
    }

    function showHideControls () {
      if (sliderCount > 1) {
        element.classList.add('controls');
        element.classList.remove('no-controls');
      } else {
        element.classList.remove('controls');
        element.classList.add('no-controls');
      }
    }
    var myFunction = debounce(function () {
      checkWidth();
      if (element.classList.contains('slider-added')) {

      } else if (sliderCount > 1) {
        element.classList.add('slider-added');
        dotNavigationSlider = lory(element, {
          //disable infinite slide due to multiple video problem on video hub
          infinite: 1,
          slidesToScroll: 1,
          enableMouseEvents: true
        });
      }
    }, 500);

    myFunction();

    window.addEventListener('resize', myFunction);
  });

  Array.prototype.slice.call(document.querySelectorAll('.lory-slider')).forEach(function (element, index) {

    element.addEventListener('before.lory.init', handleDotEvent);
    element.addEventListener('after.lory.init', handleDotEvent);
    element.addEventListener('after.lory.slide', handleDotEvent);
    element.addEventListener('on.lory.resize', handleDotEvent);

    element.addEventListener('before.lory.init', showHideControls);
    element.addEventListener('after.lory.init', showHideControls);
    element.addEventListener('after.lory.slide', showHideControls);
    element.addEventListener('on.lory.resize', showHideControls);

    element.addEventListener('before.lory.init', checkWidth);
    element.addEventListener('after.lory.init', checkWidth);
    element.addEventListener('after.lory.slide', checkWidth);
    element.addEventListener('on.lory.resize', checkWidth);
    // var dotCount3 = element.querySelectorAll('.js_slide').length;
    //   var dotContainer = simpleDots3.querySelector('.js_dots');
    var sliderCount = element.querySelectorAll('.js_slide').length;
    var dotContainer = element.querySelector('.js_dots');
    var dotNavigationSlider;
    var dotListItem = document.createElement('li');
    dotListItem.classList.add('dot');

    function handleDotEvent (e) {
      if (e.type === 'before.lory.init') {
        for (var i = 0, len = sliderCount; i < len; i++) {
          var clone = dotListItem.cloneNode();
          dotContainer.appendChild(clone);
        }
        dotContainer.childNodes[0].classList.add('active');
      }
      if (e.type === 'after.lory.init') {
        for (var i1 = 0, len1 = sliderCount; i1 < len1; i1++) {
          dotContainer.childNodes[i1].addEventListener('click', function (e) {
            dotNavigationSlider.slideTo(Array.prototype.indexOf.call(dotContainer.childNodes, e.target));
          });
        }
      }
      if (e.type === 'after.lory.slide') {
        for (var i2 = 0, len2 = dotContainer.childNodes.length; i2 < len2; i2++) {
          dotContainer.childNodes[i2].classList.remove('active');
        }
        dotContainer.childNodes[e.detail.currentSlide - 1].classList.add('active');
      }
      if (e.type === 'on.lory.resize') {
        for (var i3 = 0, len3 = dotContainer.childNodes.length; i3 < len3; i3++) {
          dotContainer.childNodes[i3].classList.remove('active');
        }
        dotContainer.childNodes[0].classList.add('active');
      }
    }

    function showHideControls () {
      if (((sliderCount > 1) && (initWidth < 768)) || ((sliderCount > 2))) {
        element.classList.add('controls');
        element.classList.remove('no-controls');
      } else {
        element.classList.remove('controls');
        element.classList.add('no-controls');
      }
    }
    var myFunction = debounce(function () {
      checkWidth();
      if (element.classList.contains('slider-added')) {

      } else if (((sliderCount === 2) && (initWidth < 768)) || ((sliderCount >= 3))) {
        element.classList.add('slider-added');
        dotNavigationSlider = lory(element, {
          //disable infinite slide due to multiple video problem on video hub
          infinite: 1,
          slidesToScroll: 1,
          enableMouseEvents: true
        });
      }
    }, 500);

    myFunction();

  window.addEventListener('resize', myFunction);
});

  Array.prototype.slice.call(document.querySelectorAll('.footer-slider')).forEach(function (element, index) {

    element.addEventListener('before.lory.init', handleDotEvent);
    element.addEventListener('after.lory.init', handleDotEvent);
    element.addEventListener('after.lory.slide', handleDotEvent);
    element.addEventListener('on.lory.resize', handleDotEvent);

    element.addEventListener('before.lory.init', showHideControls);
    element.addEventListener('after.lory.init', showHideControls);
    element.addEventListener('after.lory.slide', showHideControls);
    element.addEventListener('on.lory.resize', showHideControls);

    element.addEventListener('before.lory.init', checkWidth);
    element.addEventListener('after.lory.init', checkWidth);
    element.addEventListener('after.lory.slide', checkWidth);
    element.addEventListener('on.lory.resize', checkWidth);
    // var dotCount3 = element.querySelectorAll('.js_slide').length;
    //   var dotContainer = simpleDots3.querySelector('.js_dots');
    var sliderCount = element.querySelectorAll('.js_slide').length;
    var dotContainer = element.querySelector('.js_dots');
    var dotNavigationSlider;
    var dotListItem = document.createElement('li');
    dotListItem.classList.add('dot');

    function handleDotEvent (e) {
      if (e.type === 'before.lory.init') {
        for (var i = 0, len = sliderCount; i < len; i++) {
          var clone = dotListItem.cloneNode();
          dotContainer.appendChild(clone);
        }
        dotContainer.childNodes[0].classList.add('active');
      }
      if (e.type === 'after.lory.init') {
        for (var i1 = 0, len1 = sliderCount; i1 < len1; i1++) {
          dotContainer.childNodes[i1].addEventListener('click', function (e) {
            dotNavigationSlider.slideTo(Array.prototype.indexOf.call(dotContainer.childNodes, e.target));
          });
        }
      }
      if (e.type === 'after.lory.slide') {
        for (var i2 = 0, len2 = dotContainer.childNodes.length; i2 < len2; i2++) {
          dotContainer.childNodes[i2].classList.remove('active');
        }
        dotContainer.childNodes[e.detail.currentSlide - 1].classList.add('active');
      }
      if (e.type === 'on.lory.resize') {
        for (var i3 = 0, len3 = dotContainer.childNodes.length; i3 < len3; i3++) {
          dotContainer.childNodes[i3].classList.remove('active');
        }
        dotContainer.childNodes[0].classList.add('active');
      }
    }

    function showHideControls () {
      if ((sliderCount > 1) && (initWidth < 768)) {
        element.classList.add('controls');
        element.classList.remove('no-controls');
      } else {
        element.classList.remove('controls');
        element.classList.add('no-controls');
      }
    }
    var myFunction2 = debounce(function () {
      checkWidth();
      if (element.classList.contains('slider-added')) {

      } else if (((sliderCount > 1) && (initWidth < 768)) || ((sliderCount >= 3) && (initWidth < 800))) {
        element.classList.add('slider-added');
        dotNavigationSlider = lory(element, {
          //disable infinite slide due to multiple video problem on video hub
          infinite: 1,
          slidesToScroll: 1,
          enableMouseEvents: true
        });
      }
    }, 500);

    myFunction2();

    window.addEventListener('resize', myFunction2);
  });

  Array.prototype.slice.call(document.querySelectorAll('.acc-slider')).forEach(function (element, index) {

    element.addEventListener('before.lory.init', showHideControls);
    element.addEventListener('after.lory.init', showHideControls);
    element.addEventListener('after.lory.slide', showHideControls);
    element.addEventListener('on.lory.resize', showHideControls);

    element.addEventListener('before.lory.init', checkWidth);
    element.addEventListener('after.lory.init', checkWidth);
    element.addEventListener('after.lory.slide', checkWidth);
    element.addEventListener('on.lory.resize', checkWidth);

    element.addEventListener('before.lory.init', handleDotEvent);
    element.addEventListener('after.lory.init', handleDotEvent);
    element.addEventListener('after.lory.slide', handleDotEvent);
    element.addEventListener('on.lory.resize', handleDotEvent);

    var sliderCount = element.querySelectorAll('.js_slide').length;
    var dotContainer = element.querySelector('.js_dots');
    var dotNavigationSlider;
    var dotListItem = document.createElement('li');
    dotListItem.classList.add('dot');

    function handleDotEvent (e) {
      if (e.type === 'before.lory.init') {
        for (var i = 0, len = sliderCount; i < len; i++) {
          var clone = dotListItem.cloneNode();
          dotContainer.appendChild(clone);
        }
        dotContainer.childNodes[0].classList.add('active');
      }
      if (e.type === 'after.lory.init') {
        for (var i1 = 0, len1 = sliderCount; i1 < len1; i1++) {
          dotContainer.childNodes[i1].addEventListener('click', function (e) {
            dotNavigationSlider.slideTo(Array.prototype.indexOf.call(dotContainer.childNodes, e.target));
          });
        }
      }
      if (e.type === 'after.lory.slide') {
        for (var i2 = 0, len2 = dotContainer.childNodes.length; i2 < len2; i2++) {
          dotContainer.childNodes[i2].classList.remove('active');
        }
        dotContainer.childNodes[e.detail.currentSlide ].classList.add('active');
      }
      if (e.type === 'on.lory.resize') {
        for (var i3 = 0, len3 = dotContainer.childNodes.length; i3 < len3; i3++) {
          dotContainer.childNodes[i3].classList.remove('active');
        }
        dotContainer.childNodes[0].classList.add('active');
      }
    }

    function showHideControls () {
      if (((sliderCount > 1) && (initWidth < 600)) || ((sliderCount > 3) && (initWidth < 992)) || (sliderCount > 5)) {
        element.classList.add('controls');
        element.classList.remove('no-controls');
      } else {
        element.classList.remove('controls');
        element.classList.add('no-controls');
      }
    }

    var myFunction3 = debounce(function () {
      checkWidth();
      if (element.classList.contains('slider-added')) {

      } else if ((sliderCount > 1)) {
        element.classList.add('slider-added');
        dotNavigationSlider = lory(element, {

          // infinite: 1,
          slidesToScroll: 1,
          rewind:true,
          enableMouseEvents: true
        });
      }
    }, 500);

    myFunction3();

    window.addEventListener('resize', myFunction3);
  });

//Homepage specific Banner slider
  var slidesToScroll = 1;
  var slider = document.querySelector('.primary-slider');
  if (slider) {
    var slideCount = slider.querySelectorAll('.js_slide').length / slidesToScroll;
    var dotContainerBanner = slider.querySelector('.js_dots');
    var templateListItem = document.createElement('li');
  templateListItem.classList.add('dot');
  }

  // http://stackoverflow.com/questions/19764018/controlling-fps-with-requestanimationframe
  var stop = false;
  var frameCount = 0;
  var fps, fpsInterval, startTime, now, then, elapsed;

  // handle the slider events
  function handleEvents(e) {
    if (e.type === 'before.lory.init') {
      for (var i = 0, len = slideCount; i < len; i++) {
        var clone = templateListItem.cloneNode();
        dotContainerBanner.appendChild(clone);
      }
      dotContainerBanner.childNodes[0].classList.add('active');
    }
    if (e.type === 'after.lory.init') {
      for (var i = 0, len = slideCount; i < len; i++) {
        dotContainerBanner.childNodes[i].addEventListener('click', function(e) {
          resetTimer();
          lorySlider.slideTo(Array.prototype.indexOf.call(dotContainerBanner.childNodes, e.target) * slidesToScroll);
        });
      }
    }
    if (e.type === 'before.lory.slide') {
      slider.classList.add('mask');
    }
    if (e.type === 'after.lory.slide') {
      for (var i = 0, len = dotContainerBanner.childNodes.length; i < len; i++) {
        dotContainerBanner.childNodes[i].classList.remove('active');
      }
      dotContainerBanner.childNodes[(e.detail.currentSlide / slidesToScroll) - 1].classList.add('active');
      removeMask();
      }
    }

    var removeMask = debounce(function () {
      slider.classList.remove('mask');
    }, 600);

  // init events to handle
  if (slider) {
    slider.addEventListener('before.lory.init', handleEvents);
    slider.addEventListener('after.lory.init', handleEvents);
    slider.addEventListener('before.lory.slide', handleEvents);
    slider.addEventListener('after.lory.slide', handleEvents);

  // init the slider
  var lorySlider = lory(slider, {
    infinite: slidesToScroll,
    slidesToScroll: slidesToScroll,
    enableMouseEvents: true
  });

  }
  // begin animation (autoplay)
  function startAnimating(fps) {
    fpsInterval = 1500 / fps;
    then = Date.now();
    startTime = then;
    animate();
  }

  // animate (autoplay)
  function animate() {
    requestAnimationFrame(animate);

    now = Date.now();
    elapsed = now - then;

    if (elapsed > fpsInterval && !stop) {
      then = now - (elapsed % fpsInterval);
      lorySlider.next();
    }
  }

  // reset timer
  function resetTimer() {
    now = Date.now();
    elapsed = now - then;
    then = now - (elapsed % fpsInterval);
  }

  if (slider) {
    // start the animation process with seed time
    startAnimating(.075); // every five seconds

    // mouseover
    slider.addEventListener('mouseover', function (e) {
      stop = true;
    });

    // mouseout
    slider.addEventListener('mouseout', function (e) {
      resetTimer();
      stop = false;
    });
  }

});
